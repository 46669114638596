body {
  margin: 0;
  font-family: "Manrope", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
}

// Reference: https: //stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

@primary-color: #1EA1F1;@border-radius-base: 6px;@text-color-secondary: #0C3BB9;@pagination-font-family: Manrope;